import React from "react"
import {StopwatchIcon, InfoIcon, ArchiveIcon, ChecklistIcon, PeopleIcon, ChevronRightIcon } from '@primer/octicons-react'

const Block = ({block}) => {
  const {type, data, heading, items, number} = block

  let contents = ""
  if (items && items) {
    contents = items.map((x) => <div key={x.label} className="mb-1 flex"><div className="p-2 align-self-start" ><ChevronRightIcon size={12} verticalAlign="top"/></div><div className="">{x?.label}</div></div>)
  }
  const types = {
    none: <span></span>,
    week: <span><span className="p-1"><PeopleIcon verticalAlign="middle"/></span>{number}</span>,
    deliverable: <ChecklistIcon />,
    info: <InfoIcon />,
    thing: <ArchiveIcon/>,
    time: <StopwatchIcon />,
  }


  return (
    <div key={`${type}-${heading}`} className="relative border border-black rounded p-4 my-5">
      {heading && (<h1 className="capitalize pb-3">{heading}</h1>)}
      <div>{data}</div>
      {contents}
      <div className="absolute right-0 top-0 mx-2">
        {types[type] || <InfoIcon />}
      </div>
    </div>
  )
}

export default Block
