import React from 'react'
import Title from '../components/Title'
import Layout from '../components/Layout'
import Block from '../components/Block'
import { graphql } from 'gatsby';

const BriefTemplate = ({data}) => {
  const { documentsYaml: brief } = data
  console.log(brief.blocks);
  const blocks = brief.blocks.map((block) => <Block block={block} />);
  return (
    <Layout>
      <Title>{brief.heading}</Title>
      {blocks}
    </Layout>
  )
}

export default BriefTemplate

export const blockFields = graphql`
  fragment blockFields on DocumentsYamlBlocks { 
     number
     heading
     type
     items {
      label
      time
      notes
     }
  }
`


export const query = graphql`
  query($slug: String!) {
    documentsYaml(slug: { eq: $slug }) {
      heading
      blocks { ...blockFields }
    }
  }
`
